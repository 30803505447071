.contenedor-modal {
  width: 100%;
  height: 100vh;
  background-color: $color-verde;
  position: fixed;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  .closed {
    position: absolute;
    top: 5%;
    right: 5%;
    font-size: 30px;
    color: white;
    font-family: 'roboto-medium';
    cursor: pointer;
  }
  .info {
    width: 65%;
    height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    @media (min-width: 1600px) {
      height: 75%;
      width: 55%;
    }
    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      h2,
      p {
        color: white;
      }
      h2 {
        font-size: 35px;
        margin-bottom: 20px;
      }
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      height: 300px;
      margin-top: 30px;
      .left {
        width: 35%;
        color: white;
        display: flex;
        flex-wrap: wrap;
        padding-top: 30px;
        margin-right: 30px;
        h3 {
          font-size: 35px;
          width: 350px;
          line-height: 40px;
        }
        .porcentaje {
          width: 50%;
          h4 {
            font-size: 40px;
          }
          p {
            font-size: 20px;
            width: 80%;
          }
        }
      }
      .right {
        width: 60%;
        display: flex;
        flex-wrap: wrap;
        column-gap: 15px;
        // justify-content: flex-end;
        .carecteristicas {
          width: 48%;
          display: flex;
          align-items: center;
          img {
            width: 50px;
            margin-right: 20px;
          }
          p {
            width: 200px;
            font-size: 20px;
            color: white;
          }
          &:nth-child(2) {
            img {
              width: 42px;
            }
          }
          &:nth-child(3) {
            img {
              width: 35px;
            }
          }
          &:nth-child(4) {
            img {
              width: 38px;
            }
          }
          &:nth-child(5) {
            img {
              width: 40px;
            }
          }
        }
      }
    }
    .iconos {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      img {
        width: 130px;
        padding-right: 20px;
      }
    }
  }
}
