footer {
  width: 100%;
  height: 250px;
  background-color: $color-verde;
  display: flex;
  padding: 0 80px 50px;
  justify-content: space-between;
  @media (max-width: 1024px) {
    height: 300px;
    padding: 50px 20px 50px;
  }
  @media (max-width: 550px) {
    padding: 30px 20px 50px;
    height: 350px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 80px;
    justify-items: center;
    // align-items: center;
  }
  img {
    width: 100px;
    @media (max-width: 1024px) {
      width: 60px;
    }
  }
  .texto {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 90%;
    padding: 20px 0;
    @media (max-width: 1024px) {
      height: 150px;
      padding: 0 30px;
    }
    @media (max-width: 550px) {
      grid-column: 1/3;
      grid-row: 1/2;
      padding: 0;
    }
    img {
      width: 130px;
      margin-bottom: 20px;
    }
    p {
      color: white;
      @media (max-width: 1024px) {
        text-align: center;
        font-size: 14px;
      }
      @media (max-width: 320px) {
        font-size: 12px;
      }
    }
  }
}
