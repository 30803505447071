.contenedor_portada {
  width: 100%;
  height: 100vh;
  background-color: $color-gris;
  @media (max-width: 550px) {
    min-height: 600px;
  }
  .ornamento-top {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 8;
    opacity: 0.6;
    @media (max-width: 1024px) {
      width: 30%;
    }
    @media (max-width: 550px) {
      width: 40%;
    }
  }
  .ornamento-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    opacity: 0.6;
    @media (max-width: 1024px) {
      width: 30%;
    }
    @media (max-width: 550px) {
      width: 40%;
    }
  }
  .tipos-creditos {
    position: absolute;
    bottom: 30px;
    left: 30px;
    z-index: 9;
    width: 420px;
    @media (max-width: 550px) {
      display: none;
    }

    div {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      @media (max-width: 1024px) {
        width: 320px;
      }
      img {
        @media (max-width: 1024px) {
          width: 80px;
          &:nth-child(2) {
            width: 50px;
          }
        }
      }
    }
  }
  .arrows {
    position: absolute;
    bottom: 120px;
    left: calc(50% + 5px);
    width: $size;
    height: $size;
    transform: translate(-50%, -50%);
    z-index: 5;
    @media (max-width: 1024px) {
      display: none;
    }
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      border-left: $size/3 solid rgba(255, 255, 255, $peakopacity);
      border-bottom: $size/3 solid rgba(255, 255, 255, $peakopacity);
      transform: translate($size/3, $size * 4/3) rotate(-45deg);
      animation: arrows $speed linear infinite;
    }
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      border-left: $size/3 solid rgba(255, 255, 255, $peakopacity);
      border-bottom: $size/3 solid rgba(255, 255, 255, $peakopacity);
      transform: translate($size * 2/3, 0px) rotate(-45deg);
      animation: arrows $speed linear infinite $speed/-2;
    }
  }
  &.nosotros {
    display: flex;
    justify-content: center;
    align-items: center;
    .arrows {
      bottom: 80px;
    }
    .ornamento-bottom {
      position: absolute;
      left: auto;
      bottom: 0;
      right: 0;
      z-index: 8;
      opacity: 0.6;
    }
    .texto-sobre {
      position: absolute;
      height: 300px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      @media (min-width: 1600px) {
        height: 330px;
      }
      img {
        width: 80%;
        height: auto;
        object-fit: contain;
        @media (min-width: 1600px) {
          width: 90%;
        }
      }
      p {
        width: 700px;
        color: white;
        text-align: center;
        font-size: 18px;
      }
    }
  }
  .swiper {
    width: 100%;
    height: 100%;

    .swiper-slide {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .texto-sobre {
        position: absolute;
        gap: 60px;
        align-items: center;
        z-index: 10;
        bottom: 20%;
        
        // Slogan text
        img {
          width: 80%;
          height: auto;
          object-fit: contain;
          @media (min-width: 1600px) {
            width: 90%;
          }
          @media (max-width: 1024px) {
            width: 80%;
          }
        }
        p {
          width: 700px;
          color: white;
          text-align: center;
          font-size: 18px;
          @media (max-width: 1024px) {
            width: 80%;
          }
          @media (max-width: 550px) {
            font-size: 16px;
          }
        }
      }
      .icono-desarrollo {
        position: absolute;
        bottom: 0;
        right: 0;
        display: flex;
        align-items: center;
        padding: 50px;
        @media (max-width: 1024px) {
          right: auto;
          left: 0;
          bottom: 150px;
          padding: 20px;
        }
        @media (max-width: 550px) {
          display: none;
        }
        img {
          width: 250px;
        }
        .linea {
          width: 2px;
          height: 80px;
          background-color: $color-verde;
          margin: 0 20px;
        }
        p {
          color: white;
        }
      }
    }
    .swiper-pagination {
      bottom: 40px;
      @media (max-width: 550px) {
        bottom: 80px;
      }
    }
    .swiper-pagination-bullet {
      width: 13px;
      height: 13px;
      background-color: white;
      opacity: 1;
    }
    .swiper-pagination-bullet-active {
      background-color: $color-naranja;
    }
  }

  @keyframes arrows {
    0% {
      border-left: $size/3 solid rgba(255, 255, 255, 0);
      border-bottom: $size/3 solid rgba(255, 255, 255, 0);
      transform: translate($size/-6, $size * 4/-6) rotate(-45deg);
    }
    10%,
    90% {
      border-left: $size/3 solid rgba(255, 255, 255, 0);
      border-bottom: $size/3 solid rgba(255, 255, 255, 0);
    }
    50% {
      border-left: $size/3 solid rgba(255, 255, 255, $peakopacity);
      border-bottom: $size/3 solid rgba(255, 255, 255, $peakopacity);
      transform: translate($size/-6, 0px) rotate(-45deg);
    }
    100% {
      border-left: $size/3 solid rgba(255, 255, 255, 0);
      border-bottom: $size/3 solid rgba(255, 255, 255, 0);
      transform: translate($size/-6, $size * 4/6) rotate(-45deg);
    }
  }
}

.swiper-slide {
  position: relative;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: black;
    opacity: 0.2;
  }

  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.video-cover {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.contenedor_nosotros {
  display: flex;
  &.revert {
    flex-direction: row-reverse;
  }
  .left {
    width: 50%;
    background-color: $color-verde;
  }
  .back-ventas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -10;
    object-fit: cover;
    opacity: 0.29;
  }
  .right {
    width: 50%;
    display: flex;
    align-items: center;
    position: relative;
    padding: 245px 15px 200px;
    img {
      width: 100%;
      height: 100%;
    }
    .texto {
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      width: 700px;
      max-width: 100%;
      margin: 0 auto;
      .titulo {
        position: relative;
      }
      .ornamento-title {
        width: 200px;
        height: auto;
      }
      .logo-deesa {
        width: 300px;
        height: auto;
      }
      h2 {
        font-family: 'roboto-bold';
        font-size: 70px;
        color: $color-gris-claro;
        margin: 0;
        line-height: 1;
        @media (max-width: 550px) {
          font-size: 50px;
          line-height: 50px;
        }
      }
      h4 {
        font-size: 25px;
        color: $color-gris-claro;
        font-weight: normal;
        margin: 20px 0 30px;
        @media (max-width: 550px) {
          font-size: 20px;
        }
      }
      &__description {
        padding-left: 10px;
        p {
          margin-bottom: 40px;
        }
      }
    }
    &.concepto-nosotros {
      .titulo {
        img {
          margin-bottom: 20px;
        }
      }
    }
  }
  @media (max-width: 1024px) {
    flex-direction: column-reverse;
    &.revert {
      flex-direction: column-reverse;
    }
    .left,
    .right {
      width: auto;
    }
    .left {
      height: 500px;
    }
    .right {
      padding: 60px 30px;
    }
  }
  @media screen {
  }
}
.contenedor_cards {
  width: 100%;
  height: 360px;
  background-color: $color-verde;
  display: flex;
  position: relative;
  overflow: hidden;
  @media (max-width: 1024px) {
    flex-direction: column;
    height: 800px;
  }
  &.nosotros_cards {
    background-color: transparent;
    width: 60%;
    position: absolute;
    bottom: 0;
    height: 300px;
    right: 180px;
    z-index: 2;
    @media (min-width: 1600px) {
      height: 350px;
      width: 50%;
      right: 350px;
    }
    .leftcards {
      width: 100%;
      padding: 0;
      .card {
        .texto {
          img {
            width: 60px;
            margin-right: 20px;
          }
          p {
            font-family: 'roboto-medium';
            color: $color-gris-claro;
          }
        }
      }
    }
  }
  .leftcards {
    width: 70%;
    display: flex;
    justify-content: space-between;
    padding: 60px;
    position: absolute;
    gap: 30px;
    @media (min-width: 1600px) {
      width: 60%;
    }
    @media (max-width: 1024px) {
      width: 100%;
      flex-direction: column;
      height: 100%;
    }
    @media (max-width: 550px) {
      padding: 60px 40px;
    }
    @media (max-width: 320px) {
      padding: 60px 20px;
    }
    .HighlightCard {
      flex: 1;
    }
    // TODO: Remove once `HighlightCard` is used fully within the app
    .card {
      width: 30%;
      height: 200px;
      background-color: white;
      box-shadow: 0px 3px 30px #00000024;
      border-radius: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 1024px) {
        width: 100%;
      }
      .texto {
        display: flex;
        img {
          width: 50px;
        }
        h3 {
          font-size: 50px;
          color: $color-verde;
          font-family: 'roboto-bold';
        }
        p {
          font-size: 20px;
          color: $color-gris;
        }
      }
    }
  }
  .rightcards {
    width: 30%;
    height: 330px;
    padding: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    right: 0;
    @media (min-width: 1600px) {
      width: 35%;
    }
    @media (max-width: 1024px) {
      display: none;
    }
    img {
      width: 200px;
      @media (min-width: 1600px) {
        width: 250px;
      }
    }
  }
  .ornamento {
    position: absolute;
    right: 0;
    bottom: 0;
    // @media (max-width:1024px) {
    //     width: 100px;

    // }
    @media (max-width: 1024px) {
      display: none;
    }
    &.left {
      right: auto;
      left: 0;
      @media (max-width: 1024px) {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
        bottom: auto;
        top: 0;
      }
      @media (max-width: 550px) {
        width: 100%;
        height: 70%;
      }
    }
  }
}
.contenedor_desarrollo {
  width: 100%;
  height: 100vh;
  background-color: $color-gris-claro-2;
  position: relative;
  @media (max-width: 1024px) {
    height: 800px;
  }
  .banderines {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    @media (max-width: 1024px) {
      width: 300px;
    }
    @media (max-width: 550px) {
      width: 200px;
    }
  }
  .swiper {
    width: 100%;
    height: 100%;

    &-slide {
      display: flex;
      align-items: center;
      padding-left: 150px;
      @media (max-width: 1024px) {
        padding-left: 80px;
      }
      @media (max-width: 550px) {
        padding-left: 20px;
      }
      .texto-sobre {
        width: 50%;
        height: 55%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        @media (min-width: 1600px) {
          height: 45%;
        }
        @media (max-width: 1024px) {
          width: 100%;
          height: 45%;
        }
        @media (max-width: 320px) {
          height: 50%;
        }
        img {
          width: auto;
          max-width: 600px;
          max-height: 200px;
          min-width: 350px;
          @media (max-width: 1024px) {
            max-width: 400px;
            max-height: 130px;
            min-width: 300px;
          }
          @media (max-width: 550px) {
            max-width: 300px;
            min-width: 200px;
            max-height: 100px;
          }
          @media (max-width: 320px) {
            max-width: 250px;
            min-width: 180px;
            max-height: 80px;
          }
        }
        p {
          color: white;
          width: 610px;
          @media (max-width: 1024px) {
            width: 80%;
          }
          @media (max-width: 550px) {
            width: 95%;
          }
        }
      }
    }
  }
}
.contenedor_form_ventas {
  display: flex;
  position: relative;
  padding: 30px 0;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
  .back-ventas {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: -10;
    top: 0;
    opacity: 0.29;
  }
  .left {
    width: 55%;
    position: relative;
    @media (max-width: 1024px) {
      width: 100%;
      padding: 0;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: left;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .right {
    width: 50%;
    padding: 10px 90px;
    @media (min-width: 1600px) {
      padding: 10px 110px;
    }
    @media (max-width: 1024px) {
      width: 100%;
      padding: 10px 80px;
    }
    @media (max-width: 550px) {
      padding: 10px 20px;
    }
    .titulo {
      position: relative;
      h1 {
        font-size: 38px;
        font-family: 'roboto-bold';
        color: $color-gris-claro;
        line-height: 55px;
        font-weight: normal;
        margin: 0;
        @media (max-width: 550px) {
          font-size: 30px;
        }
        &:nth-child(3) {
          font-size: 78px;
          @media (max-width: 550px) {
            font-size: 60px;
          }
        }
      }
      .ornamento-title {
        width: 100%;
        max-width: 300px;
      }
    }
    p {
      color: $color-gris-claro;
      margin: 30px 0;
    }
  }
}
.contenedor_mision {
  background-color: $color-verde;
  position: relative;
  overflow: hidden;
  .torre {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 600px;
    height: auto;
    @media (min-width: 1600px) {
      width: 700px;
    }
  }
  .ornamento {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 0;
  }

  &-wrapper {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    width: 83.3333%; // w-10/12
    margin: 0 auto;
    padding: 250px 15px 100px;
    position: relative;
    z-index: 5;
    @media (max-width: 1024px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 100px 30px 60px;
    }
  }

  .mision-vision {
    display: flex;
    justify-content: space-evenly;
    grid-column: 3/-1;
    padding-left: 80px;
    padding-bottom: 150px;
    h1 {
      color: white;
      font-size: 70px;
      font-family: 'roboto-bold';
      padding-bottom: 40px;
      margin: 0;
    }
    .vision {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding-right: 30px;
      @media (min-width: 1600px) {
        padding-right: 50px;
      }
    }
    .mision {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding-left: 20px;
      @media (min-width: 1600px) {
        padding-left: 30px;
      }
    }

    @media (max-width: 1024px) {
      display: flex;
      flex-direction: column;
      padding-bottom: 100px;
      text-align: center;
      padding-left: 0;
      gap: 30px;
      h1 {
        padding-bottom: 20px;
      }
      .vision {
        padding-right: 0;
      }
    }
  }

  .mision-highlights {
    grid-column: 3/-1;
    display: flex;
    gap: 30px;
    padding-right: 8em;

    .HighlightCard {
      width: 30%;
    }

    @media (max-width: 1024px) {
      display: flex;
      flex-direction: column;
      padding: 0;
      margin: 0 auto;
      width: 100%;

      .HighlightCard {
        width: 100%;
        max-width: 100%;
      }
    }
    @media (max-width: 1599px) {
      padding-right: 0;
      grid-column: 2/-1;
    }
  }
}
.contenedor_nuestros_desarrollos {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 100px;
  h1 {
    font-size: 50px;
    color: $color-gris-claro;
    font-family: 'roboto-bold';
  }
  .desarrollos_iconos {
    width: 90%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: 100px;
    @media (min-width: 1600px) {
      padding-top: 130px;
    }
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 80px;
      @media (min-width: 1600px) {
        padding-bottom: 80px;
      }
      img {
        width: 60%;
        height: 100%;
        max-height: 90px;
        object-fit: contain;
      }
    }
  }

  @media (max-width: 550px) {
    .desarrollos_iconos {
      width: 100%;
      grid-template-columns: repeat(auto, minmax(150px, 1fr));
    }
  }
  @media (max-width: 1024px) {
    padding: 100px 30px;
    h1 {
      text-align: center;
      margin-bottom: 0;
    }
  }
}
.contenedor_familias {
  background-color: $color-verde;
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  position: relative;
  padding: 40px;
  overflow: hidden;
  .ornamento {
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .left {
    z-index: 3;
    grid-column: 1/5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
    .img-text {
      width: 500px;
      padding-bottom: 50px;
    }
    p {
      padding-bottom: 50px;
    }
    .reseñas {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 465px;
      max-width: 100%;
      .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .like {
        position: absolute;
        right: 0;
        top: 0;
        transform: translate(25%, -35%);
        z-index: 9;
      }
      .card-reseña {
        width: 466px;
        min-height: 142px;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 30px #00000024;
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 30px;
        position: relative;
        gap: 30px;
        .avatar {
          background-position: center;
          background-size: cover;
          width: 100px;
          height: 100px;
          border-radius: 50%;
          flex: none;
        }

        p {
          font-size: 18px;
          font-family: 'roboto-bold';
          padding-bottom: 0;
          margin-top: 0;
        }
        span {
          font-size: 18px;
        }

        @media (max-width: 479px) {
          flex-direction: column;
          padding: 15px;
          gap: 15px;

          .avatar {
            width: 60px;
            height: 60px;
            margin-right: auto;
          }
        }
      }
    }
  }
  .right {
    grid-column: 6/-1;
    .grid-videos {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, 300px);
      gap: 20px;
      .contenido {
        background-color: $color-gris-claro;
        width: 100%;
        height: 100%;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top center;
      }
    }
  }

  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    gap: 40px;
    .left {
      .img-text {
        width: 350px;
        max-width: 100%;
      }
    }
    .right {
      .grid-videos {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        grid-template-rows: auto;
        .contenido {
          // height: 240px;
          img {
            object-position: center 30%;
          }
        }
      }
    }
  }
}
.contenedor_presencia {
  display: flex;
  position: relative;
  .back-ventas {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -10;
    top: 0;
    left: 0;
    object-fit: cover;
    opacity: 0.29;
  }
  .left {
    width: 50%;
    padding: 130px 0 160px 80px;
    @media (min-width: 1600px) {
      padding: 160px 0 200px 100px;
    }
    img {
      width: 100%;
    }
  }
  .right {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h2 {
      color: $color-gris-claro;
      font-family: 'roboto-bold';
      font-size: 50px;
      padding-bottom: 20px;
    }
    padding: 100px;
    @media (min-width: 1600px) {
      padding: 0 0 0 160px;
    }
    p {
      width: 100%;
      @media (min-width: 1600px) {
        width: 80%;
      }
    }
  }

  @media (max-width: 1023px) {
    flex-direction: column-reverse;
    padding: 60px 30px;
    .left,
    .right {
      width: auto;
      padding: 0;
    }

    .left img {
      width: 600px;
      max-width: 100%;
      margin: 0 auto;
      display: block;
    }

    .right {
      text-align: center;
      margin-bottom: 30px;
      h2 {
        margin-bottom: 0;
      }
    }
  }
}

.images-carousel .swiper,
.images-carousel.swiper {
  width: 100%;
  height: 100%;

  .swiper-slide {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &.justify-start {
      justify-content: start;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .swiper-pagination {
    bottom: 40px;
    @media (max-width: 550px) {
      bottom: 80px;
    }
  }
  .swiper-pagination-bullet {
    width: 13px;
    height: 13px;
    background-color: white;
    opacity: 1;
  }
  .swiper-pagination-bullet-active {
    background-color: var(--color-naranja);
  }
}
