.CotizadorSuccessState {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 30px;

  h3 {
    font-size: 2em;
    text-transform: uppercase;
  }

  p {
    width: 500px;
    margin: 0 auto;
    font-size: 16px;
    font-weight: bold;
  }
}
