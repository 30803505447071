.CotizadorPersonalDetails {
  &-wrapper {
    flex-direction: column;
    flex-wrap: wrap;
    column-gap: 20px;
    display: flex;
    width: 1000px;
    max-width: 100%;
    margin-bottom: 30px;
    row-gap: 20px;

    @media (min-width: 1024px) {
      row-gap: 30px;
    }
  }

  &-group {
    display: grid;
    gap: 20px;
    @media (min-width: 1024px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

// TODO: Move to a global style
.Select {
  /* Reset */
  appearance: none;
  border: 0;
  outline: 0;
  border: 1px solid #7ab834;
  /* Personalize */
  width: 30%;
  height: 40px;
  padding: 0 4em 0 1em;
  color: #e2e1df;
  border-radius: 8px;
  width: 100%;
  // box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  /* <option> colors */
  option {
    color: var(--color-gris);
    background-color: white;
  }
  /* Remove focus outline */
  &:focus {
    outline: none;
  }
  /* Remove IE arrow */
  &::-ms-expand {
    display: none;
  }

  &.Select--light {
    color: var(--color-gris);
    border: none;
    height: 40px;
    outline: none;
    padding: 0 20px;

    &::placeholder {
      color: #e2e1df;
    }

    &.is-invalid {
      outline: 1px solid red;
    }
  }
}

.Input {
  width: 100%;
  border: none;
  border-radius: 8px;
  height: 40px;
  color: var(--color-gris);
  padding: 0 20px;
  outline: none;

  &::placeholder {
    color: var(--color-gris) !important;
  }

  &.is-invalid {
    outline: 1px solid red;
  }
}
