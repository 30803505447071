.contenedor_portada {
  &--desarrollo {
    position: relative;
    .button-success {
      position: absolute;
      bottom: 30%;
      left: 50%;
      transform: translateX(-50%);
      z-index: 10;
    }
  }
}
.contenedor-video-descripcion {
  width: 100%;
  padding: 120px 30px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  .izquierdo-video-descripcion {
    width: 100%;
    .izquierdo-video-descripcion-principal {
      .contenedor-video {
        display: flex;
        position: relative;
        width: 100%;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        justify-content: center;
        aspect-ratio: 16 / 9;
        .video {
          width: 100%;
          height: 100%;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .fondo_video {
            position: absolute;
            width: 100%;
            height: 90%;
          }
        }
        img {
          cursor: pointer;
          position: absolute;
          z-index: 9;
          width: 85px;
        }
      }
    }
  }
  .derecho-video-descripcion {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 100px;
    .derecho-video_logo {
      max-width: 100%;
      display: block;
      margin: 0 auto;
      margin-bottom: 80px;
    }
    .parrafo-video-descripcion {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      flex-grow: 1;
    }
    p {
      font-family: 'roboto-regular';
    }
    .btn-video-descripcion {
      background: #7ab834;
      width: 185px;
      height: 35px;
      color: #ffffff;
      border-radius: 7px;
      border-color: transparent;
      font-size: 13px;
      cursor: pointer;
      font-family: 'Montserrat-Bold';
    }
    .btn-video-descripcion:hover {
      background: #f29100;
    }
  }

  @media (max-width: 1599px) {
    .derecho-video-descripcion {
      padding: 0 60px;
    }
  }

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column-reverse;
    gap: 60px;

    .derecho-video-descripcion {
      padding: 0;
    }
  }
}
.datos-deesa {
  background: #82c341;
  padding: 60px 30px;
  &__container {
    width: 83.33%; //w-10/12
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    @media (max-width: 1023px) {
      flex-direction: column;
      gap: 60px;
    }
  }
  .datos-deesa-izquierdo {
    .datos-deesa-izquierdo-principal {
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 30px;
      .icono {
        color: #ffffff;
      }
      .deesa-ubicacion {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 12px;
        p {
          font-family: 'Montserrat-Bold';
          color: #ffffff;
          margin: 0;
        }
        .txt-img {
          display: flex;
          font-family: 'Montserrat-Bold';
          color: #ffffff;
          align-items: center;
          gap: 15px;
          img {
            width: 100px;
          }
        }
      }
    }
  }
  .datos-deesa-derecho {
    .datos-deesa-derecho-principal {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .datos-deesa__specs {
      display: grid;
      justify-content: space-between;
      align-items: center;
      grid-template-columns: repeat(2, 1fr);
      gap: 30px;
      .spec-item {
        display: inline-flex;
        align-items: center;
        gap: 16px;
        color: white;
        img {
          width: 50px;
          height: 35px;
          object-fit: contain;
        }
        p {
          white-space: nowrap;
          margin: 0;
          color: #ffffff;
          font-family: 'roboto-medium';
        }
        sup {
          font-size: 65%;
        }
      }
    }
  }
}
.imagen-desarrollo {
  width: 100%;
  height: 85vh;
  max-height: 800px;
  object-fit: cover;
  object-position: center;
  margin-bottom: -5px;
}
.virtual-tour{
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.iframe {
  width: 100%;
  height: 100vh;
  display: flex;
  .iframe-video {
    border: 0px !important;
  }
}
.ubicacion {
  background-color: #7ab834;
  padding: 60px 30px 90px;
  &-wrapper {
    width: 1024px;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    @media (max-width: 1024px) {
      flex-direction: column;
      gap: 60px;
    }
  }
  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    .logo-principal {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img {
      height: 190px;
      max-width: 250px;
      object-fit: contain;
    }
  }
  .ubicacion-principal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    color: white;
    .contenedor-ubicacion {
      margin-bottom: 22px;
      p {
        font-size: 22px;
      }
    }
    p {
      margin: 0;
      font-family: 'Montserrat-Bold';
    }
    .see-in-map {
      display: inline-flex;
      gap: 16px;
    }
  }
}
