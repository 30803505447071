.form-ventas {
  form {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 460px;
    @media (min-width: 1600px) {
      width: 75%;
    }
    @media (max-width: 1024px) {
      width: 100%;
    }
    input {
      width: 100%;
      height: 36px;
      border: 1px solid #7ab834;
      border-radius: 9px;
      padding-left: 20px;
      outline: none;
      &::placeholder {
        color: black;
      }
    }
    div {
      width: 100%;
      input {
        width: 35%;
      }
    }
    #fecha {
      background:
        url('../../../public/Assets/caret-down-solid.svg') no-repeat right 0.8em center / 1.4em,
        linear-gradient(to left, var(--color-blanco) 3em, var(--color-blanco) 3em);
      &:focus {
        background: url('');
      }
    }
    /* <select> styles */
    select {
      /* Reset */
      appearance: none;
      border: 0;
      outline: 0;
      // font: inherit;
      border: 1px solid #7ab834;
      /* Personalize */
      width: 100%;
      height: 36px;
      padding: 0 4em 0 1em;
      background:
        url('../../../public/Assets/caret-down-solid.svg') no-repeat right 0.8em center / 1.4em,
        linear-gradient(to left, var(--color-blanco) 3em, var(--color-blanco) 3em);
      color: black;
      border-radius: 9px;
      // box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
      cursor: pointer;
      /* <option> colors */
      option {
        color: inherit;
        background-color: white;
      }
      /* Remove focus outline */
      &:focus {
        outline: none;
      }
      /* Remove IE arrow */
      &::-ms-expand {
        display: none;
      }
    }
    .medio {
      display: flex;
      width: 100%;
      justify-content: flex-start;
      @media (max-width: 550px) {
        flex-wrap: wrap;
        row-gap: 10px;
      }
      input {
        display: none;
      }
      input[type='radio']:checked + label {
        border: 1px solid var(--color-verde);
        background-color: var(--color-verde);
        * {
          color: white;
        }
      }
      label {
        cursor: pointer;
        border-radius: 9px;
        border: 1px solid #7ab834;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        @media (max-width: 550px) {
          margin-right: 15px;
          padding: 5px 10px;
        }
        @media (max-width: 320px) {
          margin-right: 5px;
          padding: 5px;
        }
        img {
          width: 60px;
          @media (max-width: 550px) {
            width: 45px;
          }
        }
        svg {
          font-size: 25px;
          color: var(--color-verde);
          margin-right: 10px;
        }
        span {
          color: var(--color-verde);
          font-family: 'roboto-medium';
          @media (max-width: 550px) {
            font-size: 15px;
          }
        }
      }
    }
    .dateTime {
      display: flex;
      justify-content: space-between;
      input {
        width: 45%;
      }
      select {
        width: 45%;
      }
    }
    span {
      width: 100%;
    }
    p {
      width: 100%;
      color: black;
      font-size: 12px;
    }
    button {
      width: 100px;
      height: 40px;
      cursor: pointer;
      background-color: var(--color-verde);
      color: white;
      border: none;
      border-radius: 7px;
      font-family: 'Montserrat-Bold';
      font-weight: bold;
      align-self: flex-end;
    }
  }
}

*.error {
  text-align: left;
  border-color: red !important;
}

small.error {
  color: red;
}
