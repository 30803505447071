.contenedor_stiky {
  position: fixed;
  display: none;
  transform: rotate(-90deg);
  right: -85px;
  top: 50%;
  z-index: 999;
  @media (min-width: 1024px) {
    display: flex;
  }
  @media (max-width: 550px) {
    right: 0px;
    top: 85%;
    transform: rotate(0);
  }
  .whatsapp,
  .bbva {
    cursor: pointer;
    background-color: $color-verde;
    transition: background-color 0.5s ease;
    color: white;
    border: 0;
    display: inline-block;
    font-size: 17px;
    &:hover {
      background-color: $color-naranja;
    }

    svg {
      color: white;
    }
  }
  .whatsapp {
    margin-right: 20px;
    padding: 5px;
    @media (max-width: 550px) {
      margin: 0;
      padding: 5px 10px;
    }
    svg {
      font-size: 25px;
      @media (max-width: 550px) {
        margin-top: 3px;
        margin-left: 3px;
        font-size: 30px;
      }
    }
  }
  .bbva {
    padding: 5px;
    display: flex;
    align-items: center;
    @media (max-width: 550px) {
      display: none;
    }
    img {
      width: 50px;
      margin-left: 5px;
    }
  }
}
