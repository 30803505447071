.CotizadorWizard {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  height: 100%;
  padding: 50px 0 30px;
  gap: 20px;
  max-width: 100%;

  @media (min-width: 1600px) {
    gap: 40px;
  }

  &-title {
    color: white;
    font-size: 24px;
    font-family: 'Montserrat-Bold';
    text-align: center;
    margin: 0;
    @media (min-width: 1600px) {
      font-size: 30px;
    }
  }

  &-subtitle {
    color: white;
    max-width: 100%;
    text-align: center;
    p {
      width: 100%;
      max-width: 800px;
      margin: 0 auto;
    }
  }

  &-content {
    flex-grow: 1;
    width: 100%;
    height: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-y: auto;

    & > div {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 0 15px;
    }
  }

  &-form {
    width: 100%;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-grow: 1;

    legend {
      color: white;
      padding: 40px 0;
      font-family: 'Montserrat-Bold';
      text-transform: uppercase;
    }
  }

  &-footer {
    display: flex;
    justify-content: center;
    flex: none;
    margin-top: 20px;
  }

  &-cards {
    display: grid;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    max-width: 1500px;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));

    &--sm {
      max-width: 1000px;
      width: 100%;
    }
  }

  &-card {
    cursor: pointer;
    // width: 23%;
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 31px #00000029;
    transition: transform 0.2s ease;

    &:hover {
      transform: scale(1.05);
    }

    input {
      display: none;
    }

    input[type='radio']:checked + label,
    &.is-selected {
      border: 1px solid #fff;
    }

    label {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      img {
        width: 130px;
      }

      .border {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid white;
        width: 145px;
        height: 65px;
      }

      p {
        width: 100px;
        color: white;
        font-weight: bold;
        text-align: center;
        font-family: 'Montserrat-Bold';
      }
    }
  }

  &-actions {
    button {
      color: white;
      border: none;
      width: 140px;
      height: 40px;
      border-radius: 7px;
      background-color: var(--color-verde);
      cursor: pointer;

      &[disabled] {
        opacity: 0.5;
        cursor: not-allowed;
      }

      &.primary {
        background-color: var(--color-naranja);
      }
    }
  }

  .Steps {
    transform: scale(0.8);
    @media (min-width: 1600px) {
      transform: scale(1);
    }
  }
}
