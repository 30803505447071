.Ubicacion {
  background-color: var(--color-verde-claro);
  padding: 70px 0;
  position: relative;
  overflow: hidden;
  &-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    @media (max-width: 1024px) {
      flex-direction: column;
    }
  }
  &-logo {
    text-align: center;
    @media (max-width: 1024px) {
      margin-bottom: 60px;
    }
    img {
      width: 100%;
      max-width: 380px;
      @media (max-width: 550px) {
        width: 80%;
      }
    }
  }
  &-content {
    display: flex;
    flex-direction: column;
    color: white;
    @media (max-width: 1024px) {
      text-align: center;
    }
    p {
      color: white;
      font-size: 20px;
      @media (max-width: 550px) {
        font-size: 18px;
      }
      @media (max-width: 320px) {
        font-size: 16px;
      }
    }
    span {
      color: white;
    }
  }
  &-more {
    display: flex;
    gap: 10px;
    align-items: center;
    position: relative;
    @media (max-width: 1024px) {
      justify-content: center;
    }
    button {
      background: transparent;
      outline: none;
      border: 0;
      cursor: pointer;
    }
  }
  &-decor {
    position: absolute;
    z-index: 0;
    object-fit: cover;
    height: 100%;
    &--left {
      top: 0;
      left: 0;
      transform: translateX(-15%);
      object-position: right;
      mix-blend-mode: color-burn;
    }
    &--right {
      right: 0;
      bottom: 0;
      object-position: right;
      transform: translateX(20%);
    }
    @media (max-width: 1024px) {
      &--left {
        display: none;
      }
      &--right {
        transform: translateX(45%);
      }
    }
  }
}
