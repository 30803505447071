.Prototypes {
  &-container {
    padding: 60px 15px;
    h3 {
      text-align: center;
      font-size: 3em;
      font-weight: bold;
      margin: 0;
      text-transform: uppercase;
      font-family: 'roboto-bold';
      color: var(--color-gris);
      margin-bottom: 45px;
    }
  }

  &-models {
    max-width: 1054px;
    width: 100%;
    margin: 0 auto;
  }

  &-tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-bottom: 60px;
    flex-wrap: wrap;

    @media (max-width: 679px) {
      gap: 12px;
      margin-bottom: 15px;
    }
  }

  &-tab {
    padding: 12px;
    background-color: transparent;
    border: none;
    font-size: 16px;
    text-transform: uppercase;
    color: var(--color-verde);
    font-weight: bold;
    border-bottom: 3px solid transparent;
    cursor: pointer;

    &.is-active {
      border-color: var(--color-naranja);
    }

    @media (max-width: 479px) {
      font-size: 14px;
    }
  }

  &-panel {
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));

    &__image {
      width: 100%;
      img {
        width: 100%;
        max-width: 500px;
        margin: 0 auto;
        display: block;
        @media (max-width: 639px) {
          max-height: 500px;
          object-fit: contain;
        }
      }

      .swiper-button-prev, .swiper-button-next {
        color: var(--color-naranja)
      }
    }

    &__features {
      padding: 20px 0;

      @media (max-width: 679px) {
        padding: 20px;
      }

      h3 {
        text-align: left;
        color: var(--color-naranja);
        margin-bottom: 15px;
      }

      p {
        margin: 0;
      }

      ul {
        list-style-type: none;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 20px;
        li {
          display: flex;
          gap: 20px;
          align-items: center;
          img {
            flex: none;
            width: 30px;
            height: 30px;
            object-fit: contain;
          }
        }
      }
    }
  }
}
