.Header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: var(--z-header);
  background-color: var(--color-blanco);
  width: 100%;
  padding: 10px 15px;

  @media (min-width: 1024px) {
    padding: 15px 40px;
  }

  &-upper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @media (min-width: 1024px) {
      width: auto;
    }

    &__mobile {
      display: flex;
      gap: 12px;
      @media (min-width: 1024px) {
        display: none;
      }

      .Header-socials {
        gap: 12px;
        display: flex;
        &__container {
          display: flex;
          align-items: flex-end;
        }
      }
    }
  }

  &-logo img {
    height: 40px;
    @media (min-width: 1024px) {
      height: 50px;
    }
  }

  &-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 64px);
    transform: translateY(-110%);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    transition: transform ease-in-out 0.3s;
    background: var(--color-blanco);
    padding: 20px;
    gap: 40px;

    .menu-open & {
      transform: translateY(calc(0% + 64px));
    }

    @media (min-width: 1024px) {
      padding: 0;
      transform: none;
      position: relative;
      height: auto;
      width: auto;
      flex-direction: row;
      gap: 0;
    }

    .button-success {
      display: none;
    }

    &__links {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 0;
      margin: 0;
      width: 100%;
      gap: 30px;

      @media (min-width: 1024px) {
        flex-direction: row;
        gap: 0;
      }

      li {
        list-style: none;
        display: flex;
        align-items: center;

        a {
          @media (max-width: 1024px) {
            color: var(--color-gris-claro);
          }
          @media (max-width: 550px) {
            font-size: 33px;
          }
          &::after {
            display: flex;
            content: '';
            width: 0px;
            height: 1.5px;
            background-color: var(--color-naranja);
            transition: width 0.8s ease;
          }
          &:hover {
            &::after {
              width: 100%;
              height: 1.5px;
              @media (max-width: 1024px) {
                width: 0;
              }
            }
          }
        }

        a.phone {
          font-size: 1.5em;
          @media (min-width: 1024px) {
            font-size: initial;
          }
        }
      }
      a {
        color: var(--color-gris);
        margin-right: 30px;
        transition: color 0.5s ease;
        font-family: 'roboto-medium';
        @media (max-width: 1024px) {
          margin-right: 0;
          font-size: 32px;
        }
        @media (max-width: 550px) {
          font-size: 22px;
        }
        &:hover {
          color: var(--color-naranja);
        }
      }
    }
    .bbva {
      display: none;
      background-color: var(--color-verde);
      padding: 10px;

      align-items: center;
      @media (max-width: 550px) {
        display: flex;
        margin-bottom: 50px;
      }
      a {
        color: white;
        img {
          width: 40px;
        }
      }
    }
  }

  &-toggle {
    position: relative;
    width: 36px;
    height: 36px;
    cursor: pointer;
    background: transparent;
    border: 0;
    padding: 0;

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      height: 3.8px;
      width: 60%;
      background-color: var(--color-gris-claro);
      transition: width 0.5s;
      &::before,
      &::after {
        content: '';
        width: 100%;
        height: 100%;
        background-color: var(--color-gris-claro);
        position: absolute;
        left: 0;
        transition: width 0.5s;
      }

      &::before {
        top: -8px;
      }
      &::after {
        bottom: -7px;
      }
      &::before,
      &::after {
        transition: all 0.3s;
      }
    }
  }

  &-yourChoice button {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    border: none;
    font-size: 16px;
    gap: 12px;
    padding: 10px;
    box-shadow: 0 0 8px rgba(black, 0.2);
    background: var(--color-verde);
    color: var(--color-blanco);

    @media (min-width: 1024px) {
      display: none;
    }

    img {
      height: 20px;
    }

    &:hover {
      background: var(--color-naranja);
    }
  }

  &-socials {
    align-items: center;
    justify-content: space-evenly;
    gap: 16px;
    display: none;

    @media (min-width: 1024px) {
      display: flex;
    }

    a svg {
      color: var(--color-naranja);
      font-size: 1.75em;

      @media (min-width: 1024px) {
        font-size: 1.5em;
      }
    }
  }
}
