.Cotizador {
  &-toggle {
    cursor: pointer;
    width: 100%;
    position: fixed;
    background-color: $color-verde;
    bottom: 0;
    color: white;
    opacity: 0;
    transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
    font-size: 1em;
    text-transform: uppercase;
    padding: 15px;
    border: none;
    z-index: 1000;
    pointer-events: none;
    box-shadow: 0px -4px 6px #00000029;
    display: none;

    @media (min-width: 1024px) {
      display: block;
    }

    &.is-visible {
      opacity: 1;
      pointer-events: all;
    }
  }

  &-drawer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1001;
    height: calc(100vh - 64px);
    flex-direction: column;
    justify-content: space-between;
    background: var(--color-verde);
    transform: translateY(100%);
    transition: transform 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
    box-shadow: 0px -4px 6px #00000029;
    display: none;

    @media (min-width: 1024px) {
      display: flex;
    }

    @media (min-width: 1600px) {
      height: calc(100vh - 84px);
    }

    &.is-expanded {
      transform: translateY(0);
    }

    &__arrowDown {
      display: flex;
      color: white;
      font-size: 60px;
      font-weight: bold;
      cursor: pointer;
      background-color: transparent;
      border: 0;
      place-self: center;
    }
  }
}
