.Steps {
  width: 100%;
  max-width: 500px;
  position: relative;

  &-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-step {
    background-color: white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    color: var(--color-gris-claro);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-gris-claro-2);
    font-family: 'roboto-black';
    transition: all 300ms ease;
    transition-delay: 0s;
    position: relative;

    &__label {
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translate(-50%, -15px);
      width: 150px;
      text-align: center;
      color: var(--color-gris-claro-2);
    }

    &.is-active {
      color: var(--color-naranja);
    }
  }

  &-track {
    position: absolute;
    height: 4px;
    width: 100%;
    background-color: var(--color-gris-claro-2);
    z-index: -1;
    top: 50%;
    transform: translateY(-50%);

    &__indicator {
      position: absolute;
      height: 100%;
      width: 0%;
      background-color: var(--color-naranja);
      transition: all 300ms ease;
    }
  }
}
