.CotizadorOptions {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  gap: 30px;
  h2 {
    color: white;
    font-size: 26px;
    @media (min-width: 1024px) {
      font-size: 38px;
    }
  }
  &-cards {
    display: flex;
    width: 100%;
    max-width: 800px;
    justify-content: center;
    gap: 20px;
    padding: 0 16px;
    flex-wrap: wrap;
    align-items: center;

    &__card {
      cursor: pointer;
      width: 350px;
      height: 250px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 0px 31px #00000029;
      transition: transform 0.2s ease;
      position: relative;
      .credito {
        position: absolute;
        bottom: 20px;
        margin: 10px;
        display: flex;
        width: 55%;
        justify-content: space-between;
        img {
          width: 50px;
          &:nth-child(2) {
            width: 40px;
          }
        }
      }
      &:hover {
        transform: scale(1.05);
      }
      h4 {
        color: white;
        text-align: center;
        font-size: 24px;

        @media (min-width: 1024px) {
          font-size: 35px;
        }
      }
    }
  }
}
