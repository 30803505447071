$shadow-color: #00000024;
$card-shadow: 0px 3px 30px $shadow-color;
$card-border-radius: 22px;
$card-content-gap: 30px;
$card-icon-width: 60px;
$card-text-size: 50px;
$card-subtext-size: 20px;

.HighlightCard {
  min-height: 200px;
  background-color: white;
  box-shadow: $card-shadow;
  border-radius: $card-border-radius;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  @media (max-width: 1024px) {
    width: 100%;
  }
  &__Image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 0;
    opacity: 0;
    transition: opacity 0.3s;
  }
  &__Icon {
    color: var(--color-verde);
    flex: none;
    width: $card-icon-width;
  }
  &__Content {
    display: flex;
    align-items: center;
    gap: $card-content-gap;
    color: var(--color-verde);
    transition: color 0.3s;
    position: relative;
    h3 {
      font-size: $card-text-size;
      font-family: 'roboto-bold';
      line-height: 1;
      margin: 0;
    }
    p {
      font-size: $card-subtext-size;
      font-weight: 500;
      font-family: 'roboto-medium';
      margin: 0;
    }
  }

  &:hover {
    .HighlightCard__Image {
      opacity: 0.5;
    }
    .HighlightCard__Content {
      color: var(--color-naranja);
    }
  }
}
