.mapa {
  width: 100%;
  height: 90vh;
  @media (max-width: 1024px) {
    height: 500px;
  }
  .leaflet-container {
    width: 100%;
    height: 100%;
  }
}
