.amenidades {
  background: #82c341;
  color: white;
  padding: 80px 30px;
  h3 {
    font-size: 44px;
    font-family: 'roboto-bold';
    text-align: center;
    margin: 0;
  }
  .contenedor-amenidades {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 1024px;
    max-width: 100%;
    margin: 0 auto;
    margin-top: 80px;
    .list_amenidades {
      width: 100%;
      display: grid;
      justify-content: center;
      align-items: start;
      grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
      gap: 40px;
      .icono {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: center;
      }
      h4 {
        text-align: center;
        font-family: 'roboto-bold';
        font-size: 22px;
      }
      .iconos {
        color: #ffffff;
        background-color: #82c341;
      }
      .iconos:hover {
        color: #f29100 !important;
      }
      img {
        height: 170px;
        width: 100%;
        object-fit: none;
        object-position: center;
      }
    }
  }
}
