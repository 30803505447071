$color-naranja: #f29100;
$color-verde: #7ab834;
$color-gris: #515151;
$color-gris-claro: #7a7a7a;
$color-gris-claro-2: #d2d4d0;
$glass: rgb(255, 255, 255);
$glass-icon: rgb(255, 255, 255);
$gradient: linear-gradient(35deg, red, purple);

:root {
  --color-verde: #7ab834;
  --color-verde-claro: #82c341;
  --color-gris: #515151;
  --color-gris-claro: #7a7a7a;
  --color-gris-claro-2: #d2d4d0;
  --color-naranja: #f29100;
  --color-blanco: #fff;

  // Elevation
  --z-header: 9999;
}

$option: #320a28;

$size: 20px;
$speed: 3s;
$peakopacity: 0.7;
@font-face {
  font-family: 'roboto-regular';
  src: url('../fonts/Roboto/Roboto-Regular.ttf');
}
@font-face {
  font-family: 'roboto-medium';
  src: url('../fonts/Roboto/Roboto-Medium.ttf');
}
@font-face {
  font-family: 'roboto-black';
  src: url('../fonts/ROBOTO-BLACK.TTF');
}
@font-face {
  font-family: 'roboto-bold';
  src: url('../fonts/Roboto/Roboto-Bold.ttf');
}
@font-face {
  font-family: 'Montserrat-Bold';
  src: url('../fonts/Montserrat-Bold.ttf');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  src: url('../fonts/Montserrat-Bold.ttf');
}
.font-family-montserrat {
  font-family: 'Montserrat';
}
.font-bold {
  font-weight: 600;
}
html {
  scroll-behavior: smooth;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'roboto-regular';
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h4 {
  font-weight: normal !important;
}

.button-success {
  background-color: $color-verde;
  color: white;
  padding: 10px 20px 11px;
  border-radius: 5px;
  text-decoration: none;
  transition: background 0.4s ease;
  display: inline-block;
  &:hover {
    background-color: $color-naranja;
  }
}
.button-success-2 {
  background-color: $color-naranja;
  color: white;
  padding: 10px 21px 11px;
  border-radius: 5px;
  text-decoration: none;
  transition: background 0.4s ease;
  &:hover {
    background-color: $color-verde;
  }
}
a {
  font-size: 17px;
  color: $color-gris;
  text-decoration: none;
  @media (max-width: 550px) {
    font-size: 16px;
  }
}

// Transitions
.show-enter {
  opacity: 0;
  transform: scale(0.9);
}
.show-enter-active {
  opacity: 0.5;
  transform: translateX(0);
  transition:
    opacity 0.5s,
    transform 0.5s;
}
.show-enter-done {
  opacity: 1;
}
.show-exit {
  opacity: 1;
}
.show-exit-active {
  opacity: 0.5;
  transform: scale(0.9);
  transition:
    opacity 0.1s,
    transform 0.5s;
}
.show-exit-done {
  opacity: 0;
}

.fade-enter {
  opacity: 0;
}
.fade-exit {
  opacity: 1;
}
.fade-enter-active {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
}
.fade-enter-active,
.fade-exit-active {
  transition: opacity 500ms;
}

// Utility classes
.hidden-sm {
  @media (max-width: 1024px) {
    display: none;
  }
}
.m-0 {
  margin: 0 !important;
}
.relative {
  position: relative;
}
.whitespace-nowrap {
  white-space: nowrap;
}

.object-contain {
  object-fit: contain !important;
}
